export class EditLaneModel {
  Id?: number;
  IsSendTone = false;
  IsActive = true;
  Lane_Number?: number;
  FacilityLaneCode?: number = null;
  Intercom_Installed?: number = null;
  Intercom_Manufacturer?: number = null;
  Intercom_Type = '';
  fkLot?: number = null;
  Gate_Vend_Code: string;
  TerminateCallTone: string = null;
  VoicePlatformDeviceId?: string;
  IsNew = false;
  Lane_Type?: string;
  DVR_Channel?: string;
  Accept_Payments: boolean | null;
  StreamSourceType: IStreamSourceType;
  RtspTransport?: IRTSPTransportType;
  RtspAddress?: string;
  RtspUsername?: string;
  RtspPassword?: string;
  DialNumber?: number;
}

export enum IStreamSourceType {
  None,
  DVR,
  RTSP,
}

export enum IRTSPTransportType {
  UDP,
  TCP,
}

export const RTSP_TRANSPORT_TYPES = [
  { Id: 0, Name: 'UDP' },
  { Id: 1, Name: 'TCP' },
];

export const STREAM_SOURCE_TYPES = [
  { Id: 0, Name: 'None' },
  { Id: 1, Name: 'DVR' },
  { Id: 2, Name: 'RTSP' },
];

export function getRTSPTransportTypeNameById(id: number) {
  return RTSP_TRANSPORT_TYPES.find((type: { Id: number; Name: string }) => type.Id === id)?.Name || '';
}
