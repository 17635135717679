export interface IGoogleTimeZone {
  TimeZoneId: string;
  TimeZoneName: string;
  TimeZoneIdAdaptive: string;
}

export interface ISystemTimeZone {
  Id: string;
  Name: string;
  GMTDisplay: string;
  IANAName: string;
}

export const GOOGLE_TIMEZONE_LIST: IGoogleTimeZone[] = [
  { TimeZoneId: 'America/New_York', TimeZoneName: 'Eastern (most areas)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Detroit', TimeZoneName: 'Eastern - MI (most areas)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Kentucky/Louisville', TimeZoneName: 'Eastern - KY (Louisville area)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Kentucky/Monticello', TimeZoneName: 'Eastern - KY (Wayne)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Indianapolis', TimeZoneName: 'Eastern - IN (most areas)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Vincennes', TimeZoneName: 'Eastern - IN (Da, Du, K, Mn)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Winamac', TimeZoneName: 'Eastern - IN (Pulaski)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Marengo', TimeZoneName: 'Eastern - IN (Crawford)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Petersburg', TimeZoneName: 'Eastern - IN (Pike)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Indiana/Vevay', TimeZoneName: 'Eastern - IN (Switzerland)', TimeZoneIdAdaptive: 'EST' },
  { TimeZoneId: 'America/Chicago', TimeZoneName: 'Central (most areas)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/Indiana/Tell_City', TimeZoneName: 'Central - IN (Perry)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/Indiana/Knox', TimeZoneName: 'Central - IN (Starke)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/Menominee', TimeZoneName: 'Central - MI (Wisconsin border)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/North_Dakota/Center', TimeZoneName: 'Central - ND (Oliver)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/North_Dakota/New_Salem', TimeZoneName: 'Central - ND (Morton rural)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/North_Dakota/Beulah', TimeZoneName: 'Central - ND (Mercer)', TimeZoneIdAdaptive: 'CST' },
  { TimeZoneId: 'America/Denver', TimeZoneName: 'Mountain (most areas)', TimeZoneIdAdaptive: 'MST' },
  { TimeZoneId: 'America/Boise', TimeZoneName: 'Mountain - ID (south); OR (east)', TimeZoneIdAdaptive: 'MST' },
  { TimeZoneId: 'America/Phoenix', TimeZoneName: 'MST - AZ (except Navajo)', TimeZoneIdAdaptive: 'MST' },
  { TimeZoneId: 'America/Los_Angeles', TimeZoneName: 'Pacific', TimeZoneIdAdaptive: 'PST' },
  { TimeZoneId: 'America/Anchorage', TimeZoneName: 'Alaska (most areas)', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Juneau', TimeZoneName: 'Alaska - Juneau area', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Sitka', TimeZoneName: 'Alaska - Sitka area', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Metlakatla', TimeZoneName: 'Alaska - Annette Island', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Yakutat', TimeZoneName: 'Alaska - Yakutat', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Nome', TimeZoneName: 'Alaska (west)', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'America/Adak', TimeZoneName: 'Alaska - western Aleutians', TimeZoneIdAdaptive: 'AKST' },
  { TimeZoneId: 'Pacific/Honolulu', TimeZoneName: 'Hawaii', TimeZoneIdAdaptive: 'HST' },
  {
    TimeZoneId: 'America/St_Johns',
    TimeZoneName: 'Newfoundland; Labrador (southeast)',
    TimeZoneIdAdaptive: 'NST',
  },
  {
    TimeZoneId: 'America/Halifax',
    TimeZoneName: 'Atlantic - NS (most areas); PE',
    TimeZoneIdAdaptive: 'AST',
  },
  {
    TimeZoneId: 'America/Glace_Bay',
    TimeZoneName: 'Atlantic - NS (Cape Breton)',
    TimeZoneIdAdaptive: 'AST',
  },
  {
    TimeZoneId: 'America/Moncton',
    TimeZoneName: 'Atlantic - New Brunswick',
    TimeZoneIdAdaptive: 'AST',
  },
  {
    TimeZoneId: 'America/Goose_Bay',
    TimeZoneName: 'Atlantic - Labrador (most areas)',
    TimeZoneIdAdaptive: 'AST',
  },
  {
    TimeZoneId: 'America/Toronto',
    TimeZoneName: 'Eastern - ON, QC (most areas)',
    TimeZoneIdAdaptive: 'EST',
  },
  {
    TimeZoneId: 'America/Iqaluit',
    TimeZoneName: 'Eastern - NU (most areas)',
    TimeZoneIdAdaptive: 'EST',
  },
  {
    TimeZoneId: 'America/Winnipeg',
    TimeZoneName: 'Central - ON (west); Manitoba',
    TimeZoneIdAdaptive: 'CST',
  },
  {
    TimeZoneId: 'America/Resolute',
    TimeZoneName: 'Central - NU (Resolute)',
    TimeZoneIdAdaptive: 'CST',
  },
  {
    TimeZoneId: 'America/Rankin_Inlet',
    TimeZoneName: 'Central - NU (central)',
    TimeZoneIdAdaptive: 'CST',
  },
  {
    TimeZoneId: 'America/Edmonton',
    TimeZoneName: 'Mountain - AB; BC (E); NT (E); SK (W)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Cambridge_Bay',
    TimeZoneName: 'Mountain - NU (west)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Inuvik',
    TimeZoneName: 'Mountain - NT (west)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Vancouver',
    TimeZoneName: 'Pacific - BC (most areas)',
    TimeZoneIdAdaptive: 'PST',
  },
  {
    TimeZoneId: 'America/Blanc-Sablon',
    TimeZoneName: 'AST - QC (Lower North Shore)',
    TimeZoneIdAdaptive: 'AST',
  },
  {
    TimeZoneId: 'America/Atikokan',
    TimeZoneName: 'EST - ON (Atikokan); NU (Coral H)',
    TimeZoneIdAdaptive: 'EST',
  },
  {
    TimeZoneId: 'America/Dawson',
    TimeZoneName: 'MST - Yukon (west)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Creston',
    TimeZoneName: 'MST - BC (Creston)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Dawson_Creek',
    TimeZoneName: 'MST - BC (Dawson Cr, Ft St John)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Fort_Nelson',
    TimeZoneName: 'MST - BC (Ft Nelson)',
    TimeZoneIdAdaptive: 'MST',
  },
  {
    TimeZoneId: 'America/Whitehorse',
    TimeZoneName: 'MST - Yukon (east)',
    TimeZoneIdAdaptive: 'MST',
  },

  {
    TimeZoneId: 'America/Regina',
    TimeZoneName: 'CST - SK (most areas)',
    TimeZoneIdAdaptive: 'CST',
  },
  {
    TimeZoneId: 'America/Swift_Current',
    TimeZoneName: 'CST - SK (midwest)',
    TimeZoneIdAdaptive: 'CST',
  },
];

export const TIME_ZONES: ISystemTimeZone[] = [
  {
    Id: 'EST',
    Name: 'Eastern Standard Time',
    GMTDisplay: 'GMT-05:00',
    IANAName: 'America/New_York'
  },
  {
    Id: 'CST',
    Name: 'Central Standard Time',
    GMTDisplay: 'GMT-06:00',
    IANAName: 'America/Chicago'
  },
  {
    Id: 'PST',
    Name: 'Pacific Standard Time',
    GMTDisplay: 'GMT-08:00',
    IANAName: 'America/Los_Angeles'
  },
  {
    Id: 'MST',
    Name: 'Mountain Standard Time',
    GMTDisplay: 'GMT-07:00',
    IANAName: 'America/Denver'
  },
  {
    Id: 'HST',
    Name: 'Hawaii Standard Time',
    GMTDisplay: 'GMT-10:00',
    IANAName: 'Pacific/Honolulu'
  },
  {
    Id: 'AKST',
    Name: 'Alaska Standard Time',
    GMTDisplay: 'GMT-09:00',
    IANAName: 'America/Anchorage'
  },
  {
    Id: 'AST',
    Name: 'Atlantic Standard Time',
    GMTDisplay: 'GMT-04:00',
    IANAName: 'America/Halifax'
  },
  {
    Id: 'NST',
    Name: 'Newfoundland Standard Time',
    GMTDisplay: 'GMT-03:30',
    IANAName: 'America/St_Johns'
  },
];
