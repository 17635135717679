import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import {
  ANALYTICS,
  AppNotificationsService,
  CreateTicketModel,
  IAnalyticsService,
  IncidentService,
  TicketsService,
  TicketStatus,
} from '@libs/portal-common';
import { Location } from '@angular/common';
import { NavbarItemsService } from '../../../../services/access-management/navbar-items.service';
import { IncomingCall } from '../../../../abstractions';
import { filter } from 'rxjs/operators';
import { FormBuilder, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AgentAnalyticsEvent } from '../../../../services';
import { DefaultReceipt, IReceipt } from '../../../../abstractions/receipt';

@Component({
  templateUrl: 'receipt-modal.component.html',
  styleUrls: ['receipt-modal.component.scss'],
})
export class ReceiptModalComponent implements OnInit {
  @ViewChild('receiptModal', { static: true }) public receiptModal: ModalDirective;
  @ViewChild('form', { static: false }) public form: NgForm;

  private subscriptions = new Subscription();
  ticket = new CreateTicketModel();
  incomingCall: IncomingCall;
  receipt: IReceipt = DefaultReceipt();
  ticketStatuses = new Array<TicketStatus>();
  modalConfig: ModalOptions = { backdrop: 'static', keyboard: false, show: true };
  validationMessage = '';
  validate = false;
  initialized = false;

  constructor(
    private bsModalService: BsModalService,
    private ticketsService: TicketsService,
    private incidentService: IncidentService,
    private notifications: AppNotificationsService,
    private location: Location,
    private navbarItemsService: NavbarItemsService,
    private formBuilder: FormBuilder,
    @Inject(ANALYTICS) private analytics: IAnalyticsService,
  ) {}

  ngOnInit() {
    this.receiptModal.config = this.modalConfig;
    this.initialized = false;
    this.subscriptions.add(
      this.navbarItemsService.incomingCall$
        .pipe(filter((incomingCall) => !!incomingCall))
        .subscribe((incomingCall: IncomingCall) => {
          this.incomingCall = incomingCall;
          if (this.incomingCall?.location?.LotName) {
            this.receipt.ParkingLocation = this.incomingCall.location.LotName;
          }
          this.receipt.DateOfTransaction = new Date(Date.now()).toLocaleString();
        }),
    );
    setTimeout(() => (this.initialized = true), 30);

    this.analytics.track(AgentAnalyticsEvent.ReceiptOpen, null);
  }

  save() {
    this.validate = true;
    this.validationMessage = '';
    if (this.form.valid) {
      this.receipt.CashCreditAmount = `${this.receipt.CashCreditAmount}:C2`;

      this.incidentService.sendReceipt(this.receipt).subscribe(
        (response) => {
          if (!response.Success) {
            this.notifications.error(response.Message);
            return;
          }
          this.validate = false;

          this.notifications.success('Receipt sent');
        },
        (err) => {
          this.notifications.error(err.Message || 'Error when sending Receipt');
        },
      );

      this.close();
      this.analytics.track(AgentAnalyticsEvent.ReceiptSaved, null);
    }
  }

  close() {
    this.receiptModal.hide();
    this.location.back();
    this.subscriptions.unsubscribe();
  }
}
