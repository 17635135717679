export interface IReceipt {
  CustomerName?: string;
  CustomerEmail?: string;
  CashCreditAmount?: string;
  ParkingLocation?: string;
  DateOfTransaction?: string;
  Address?: string;
  City?: string;
  StateProvince?: string;
  ZipCode?: string;
  Phone?: string;
  LicensePlate?: string;
}

export function DefaultReceipt() {
  return {
    CustomerName: '',
    CustomerEmail: null,
    CashCreditAmount: null,
    ParkingLocation: '',
    DateOfTransaction: null,
    Address: null,
    City: '',
    StateProvince: '',
    ZipCode: '',
    Phone: null,
    LicensePlate: '',
  };
}
