import { BaseResponse } from '../models/rest/baseResponse';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

interface ICallCenterJobResponse extends BaseResponse {
  CallCenterJob: ICallCenterJob;
}

export interface ICallCenterJob {
  Id?: number;
  Name: string;
  Period: CallCenterJobsFrequencyType;
  StartHour: number;
  DayOfWeek: number | null;
  DayOfMonth: number | string | null;
  IsActive: boolean;
  Type: CallCenterJobsReportType;
}

export function getNewCallCenterJobModel(): ICallCenterJob {
  return {
    Name: '',
    Period: 0,
    DayOfWeek: null,
    DayOfMonth: null,
    StartHour: 0,
    IsActive: false,
    Type: 0,
  };
}

export enum CallCenterJobsFrequencyType {
  Day,
  Week,
  Month,
}

export function optimizePeriod(period: any): CallCenterJobsFrequencyType {
  if (period === 'Day') {
    return CallCenterJobsFrequencyType.Day;
  }
  if (period === 'Week') {
    return CallCenterJobsFrequencyType.Week;
  }
  if (period === 'Month') {
    return CallCenterJobsFrequencyType.Month;
  }
  return period;
}

export const CallCenterJobsFrequencyTypeDisplayNames: string[] = ['Daily', 'Weekly', 'Monthly'];

export enum CallCenterJobsReportType {
  Summary,
  Location,
}

export function getCallCenterJobFrequencyTypeList() {
  return [
    {
      Id: CallCenterJobsFrequencyType.Day,
      Name: CallCenterJobsFrequencyTypeDisplayNames[CallCenterJobsFrequencyType.Day],
    },
    {
      Id: CallCenterJobsFrequencyType.Week,
      Name: CallCenterJobsFrequencyTypeDisplayNames[CallCenterJobsFrequencyType.Week],
    },
    {
      Id: CallCenterJobsFrequencyType.Month,
      Name: CallCenterJobsFrequencyTypeDisplayNames[CallCenterJobsFrequencyType.Month],
    },
  ];
}

export function getCallCenterJobReportTypeList() {
  return [
    {
      Id: CallCenterJobsReportType.Summary,
      Name: CallCenterJobsReportType[CallCenterJobsReportType.Summary],
    },
    {
      Id: CallCenterJobsReportType.Location,
      Name: CallCenterJobsReportType[CallCenterJobsReportType.Location],
    },
  ];
}

export function getHoursView(): string[] {
  return [
    '12:00 AM',
    '1:00 AM',
    '2:00 AM',
    '3:00 AM',
    '4:00 AM',
    '5:00 AM',
    '6:00 AM',
    '7:00 AM',
    '8:00 AM',
    '9:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '1:00 PM',
    '2:00 PM',
    '3:00 PM',
    '4:00 PM',
    '5:00 PM',
    '6:00 PM',
    '7:00 PM',
    '8:00 PM',
    '9:00 PM',
    '10:00 PM',
    '11:00 PM',
  ];
}

export function getSetDayOfMonth(): number[] {
  return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27];
}

export interface DayOfWeek {
  Name: string;
  Id: string | number;
}

export function getSetDayOfWeek(): DayOfWeek[] {
  return [
    { Name: 'Sunday', Id: 0 },
    { Name: 'Monday', Id: 1 },
    { Name: 'Tuesday', Id: 2 },
    { Name: 'Wednesday', Id: 3 },
    { Name: 'Thursday', Id: 4 },
    { Name: 'Friday', Id: 5 },
    { Name: 'Saturday', Id: 6 },
  ];
}

@Injectable({ providedIn: 'root' })
export class CallCenterJobsService {
  url = 'CallCenter/Jobs';

  constructor(private _httpClient: HttpClient) {}

  getAll(): Observable<ICallCenterJob[]> {
    return this._httpClient.get<ICallCenterJob[]>(this.url);
  }

  create(callCenterId: number, digest: ICallCenterJob): Observable<any> {
    let url = `CallCenter/${callCenterId}/Jobs`;
    return this._httpClient.post<ICallCenterJobResponse>(url, { CallCenterJob: digest });
  }

  update(callCenterId: number, digest: ICallCenterJob): Observable<any> {
    let url = `CallCenter/${callCenterId}/Jobs`;
    return this._httpClient.put<ICallCenterJob>(url, { CallCenterJob: digest });
  }

  delete(Id: number): Observable<any> {
    return this._httpClient.delete<ICallCenterJob>(this.url + `/${Id}`);
  }

  public convertStartHourNumberForTimeView(startHour: number): string {
    if (!startHour) {
      return '12:00 AM';
    }
    let USATimeTicker = '';
    if (startHour === 0) {
      startHour = 12;
      USATimeTicker = 'AM';
    }
    if (startHour > 0 && startHour < 12) {
      USATimeTicker = 'AM';
    }
    if (startHour === 12) {
      USATimeTicker = 'PM';
    }
    if (startHour > 12) {
      startHour = startHour - 12;
      USATimeTicker = 'PM';
    }

    return `${startHour}:00 ${USATimeTicker}`;
  }

  public convertWeekDayNumberForWeekView(weekDayNumber: number): string {
    switch (weekDayNumber) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
        break;
    }
  }

  public convertHourViewToStartHourNumber(hourView: string): number {
    let newStartHour24Standard;
    const USATimeTicker = hourView.split(' ')[1];
    const hour = Number(hourView.split(':')[0]);
    if (hour === 12 && USATimeTicker === 'PM') {
      newStartHour24Standard = 12;
    }

    if (hour === 12 && USATimeTicker === 'AM') {
      newStartHour24Standard = 0;
    }

    if (hour !== 12) {
      newStartHour24Standard = USATimeTicker === 'PM' ? 12 + hour : hour;
    }
    return newStartHour24Standard;
  }
}
