export interface Contact {
  Id: number;
  fkUserAuth?: number;
  Name: string;
  IsActive: boolean;
  IsPerson: boolean;
  Email: string;
  NotificationEmail: string;
  Occupation: string;
  Address: string;
  City: string;
  Zip: string;
  Country: string;
  TimeZone: string;
  Phones: ContactPhone[];
  Shifts: Shift[];
  Notes: string;
  Modified: any;
  IsAway: boolean;
  IsTesting: boolean;
  UserName: string;
  fkResourcesHierarchy: number;
  IsAgent: boolean;
  SipAddress: string;
  fkRegion: number;
}

export interface LocationContact {
  Id: number;
  fkUserAuth?: number;
  Name: string;
  IsActive: boolean;
  IsPerson: boolean;
  Email: string;
  NotificationEmail: string;
  Occupation: string;
  Address: string;
  City: string;
  Zip: string;
  Country: string;
  TimeZone: string;
  LocationContactPhoneNumbers: LocationContactPhoneNumber[];
  ContactShifts: Shift[];
  Shifts: LocationContactShift[];
  Notes: string;
  Modified: any;
  IsAway: boolean;
  IsTesting: boolean;
  UserName: string;
  fkResourcesHierarchy: number;
  IsAgent: boolean;
  SipAddress: string;
  fkRegion: number;
  fkLot: number;
  fkContact: number;
  fkContactShift: number;
  IsNotify: boolean;
  IsDigestLog: boolean;
  IsAgentPage: boolean;
  Meta: string;
  ContactOrder: number;
  ContactPhones: ContactPhone[];
}

export interface LocationContactShift {
  fkContact: number;
  fkContactShift: number;
  fkLot: number;
  fkLotContact: number;
}

export interface LocationContactPhoneNumber {
  fkContact: number;
  fkContactPhoneNumber: number;
  fkLot: number;
  fkLotContact: number;
}

export interface ContactIssue {
  fkIssue: number;
  fkContact: number;
}

export enum ContactPhoneType {
  PRIMARY,
  MOBILE,
  OFFICE,
}

export interface ContactPhone {
  Id: number;
  Phone: string;
  Type: ContactPhoneType;
  fkContact: number;
}

export interface Shift {
  Id: number;
  fkContact: number;
  Shift: string;
  fkLot?: number;
}

export function ContactPhoneTypeToString(type: ContactPhoneType) {
  switch (type) {
    case 0:
      return 'Primary';
    case 1:
      return 'Mobile';
    case 2:
      return 'Office';
    default:
      return 'Primary';
  }
}

export function ContactPhoneTypeListInit() {
  return [
    { Id: 0, Name: ContactPhoneTypeToString(ContactPhoneType.PRIMARY) },
    { Id: 1, Name: ContactPhoneTypeToString(ContactPhoneType.MOBILE) },
    { Id: 2, Name: ContactPhoneTypeToString(ContactPhoneType.OFFICE) },
  ];
}
