import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import { State } from '@progress/kendo-data-query';

import { GridDataResult } from '@progress/kendo-angular-grid';

import { HttpClient } from '@angular/common/http';

import { AppNotificationsService } from '@libs/portal-common/system';
import { DateToStringUtc } from './extensions/date-to-string';

export class EmailAuditFilter {
  Start: Date = new Date(Date.now() + 12096e5);
  End: Date = new Date(Date.now());
  Recipient?: string;
}

@Injectable({ providedIn: 'root' })
export class EmailAuditService extends BehaviorSubject<GridDataResult> {
  defaultState: State = {
    skip: 0,
    take: 20,
    group: [],
    filter: { logic: 'and', filters: [] },
    sort: [],
  };

  constructor(
    private _httpClient: HttpClient,
    private notifications: AppNotificationsService,
  ) {
    super(null);
  }

  public query(filter: EmailAuditFilter, state: State): void {
    this.getEmailAuditList(filter, state).subscribe((x) => {
      super.next(x);
    });
  }

  public clear(): void {
    super.next(<GridDataResult>{
      data: [],
      total: 0,
    });
  }

  getEmailAuditList(filter: EmailAuditFilter, state: State = this.defaultState): Observable<GridDataResult> {
    let requestFilter = this.constructFilter(filter, state);

    let params = {
      group: state.group,
      filter: state.filter,
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    return this._httpClient.post<any>('Alerts/Error/List', requestFilter).pipe(
      tap((response) => {
        this.notifications.checkResponse(response);
      }),
      map(
        (response) =>
          <GridDataResult>{
            data: response.Groups || response.Data || [],
            total: response.Total,
          },
      ),
    );
  }

  resendEmail(emailId: number): Observable<boolean> {
    return this._httpClient.post<any>('Alerts/Error/' + emailId + '/Resend', {}).pipe(
      tap((response) => {
        if (response.Success) {
          this.notifications.success('Email notification was queued successfully.');
        } else {
          this.notifications.error('Failed to queue the email notification. Please try again or contact support.');
        }
      }),
      map((response) => response.Success),
    );
  }

  constructFilter(filter: EmailAuditFilter, state: State) {
    let stateFilter = JSON.parse(
      JSON.stringify(
        state.filter || {
          logic: 'and',
          filters: [],
        },
      ),
    );

    let params = {
      group: state.group,
      filter: stateFilter,
      skip: state.skip,
      limit: state.take,
      sort: state.sort,
    };

    let filtersArr = params.filter.filters;

    if (filter.Start) {
      filtersArr.push({ field: 'CreatedTime', operator: 'gt', value: DateToStringUtc(filter.Start) });
    }

    if (filter.End) {
      filtersArr.push({ field: 'CreatedTime', operator: 'lt', value: DateToStringUtc(filter.End) });
    }

    if (filter.Recipient) {
      filtersArr.push({ field: 'To', operator: 'contains', value: filter.Recipient });
    }

    return params;
  }
}
