import { Injectable, InjectionToken } from '@angular/core';
import { TransferState, makeStateKey } from '@angular/platform-browser';

import { HttpClient } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

import { tap } from 'rxjs/operators';

export type ApplicationMode = 'portal' | 'webagent';
export interface IAppConfigurationBase {
  paths: {
    baseUrl: string;
  };
  keys: {
    googleApi: string;
    appKey: string;
    appSecret: string;
  };
  uiCustomization: {
    ticketsEnabled: boolean;
    monitoringOff: boolean;
    statusPageEnabled: boolean;
    statusPageCachetUrl: string;
  };
  applicationOptions: {
    defaultLoginPage: string;
    companyName: string;
    sipPatterns: Array<{ pattern: string; replacement: string }>;
  };
  auth: {
    aadClientId: string;
    aadAuthority: string;
  };
  powerBiDashboardsCredentials: {
    type: string;
    tenantId: string;
    groupId: string;
    client_id: string;
    dashboardId: string;
    client_secret: string;
  };
  fullStory: {
    orgId: string;
  };
}

export const APP_CONFIGURATION = new InjectionToken<AppConfigurationService<IAppConfigurationBase>>('APP_CONFIGURATION');

@Injectable({ providedIn: 'root' })
export abstract class AppConfigurationService<T extends IAppConfigurationBase> {
  private _data: T;
  private _promise: Promise<T>;

  private get baseUrl(): string {
    let baseUrl: string = this.data.paths.baseUrl;
    return baseUrl.replace(/\/$/, '');
  }

  public get data(): T {
    return this._data;
  }
  public get apiBaseUrl(): string {
    return `${this.baseUrl}/api`;
  }
  public get signalrBaseUrl(): string {
    return `${this.baseUrl}/signalr`;
  }

  public abstract get mode(): ApplicationMode;

  private get originUrl(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.origin;
    }

    return this.ssrOriginUrl;
  }

  constructor(
    private _httpClient: HttpClient,
    private platformId: Object,
    private ssrOriginUrl: string,
    private transferState: TransferState,
  ) {}

  load(): Promise<T> {
    const config = this.transferState.get(makeStateKey<string>('clientConfiguration'), null);
    if (!!config) {
      this._data = JSON.parse(config);
      this._promise = Promise.resolve(this._data);
    }

    if (!this._promise) {
      const path = `${this.originUrl}/clientConfiguration.json`;

      this._promise = this._httpClient
        .get<T>(path)
        .pipe(
          tap(
            (res) => {
              this._data = Object.assign(this._data || {}, res);
              this.transferState.set(makeStateKey<string>('clientConfiguration'), JSON.stringify(res));
            },
            (error) => {
              console.log('Failed to load Client configuration', error);
            },
          ),
        )
        .toPromise();
    }

    return this._promise;
  }
}
